<template>
  <div>
    <div class="flex justify-between items-center">
      <h2 class="m-0">
        {{ $t('developer-tab.api-credentials.create-form.title') }}
      </h2>
    </div>
    <div class="mt-8">
      <form @submit.prevent="createCredential">
        <div class="d-flex flex-column gap-2 mb-3">
          <Field
            :label="
              $t('developer-tab.api-credentials.create-form.form.title.label')
            "
            :placeholder="
              $t(
                'developer-tab.api-credentials.create-form.form.title.placeholder'
              )
            "
            type="text"
            class="flex-fill"
            v-model="form.title"
            :error="errors[`title`]"
          />
          <p class="mb-0">
            {{
              $t(
                'developer-tab.api-credentials.create-form.form.services.label'
              )
            }}
          </p>
          <Select
            :options="servicesOptions"
            v-model="form.services"
            multiple
            :allowEmpty="true"
            :preselectFirst="false"
            :placeholder="
              $t(
                'developer-tab.api-credentials.create-form.form.services.placeholder'
              )
            "
            :error="errors['services']"
          >
          </Select>
        </div>
        <button class="w-100 btn btn-primary btn-sm" type="submit">
          {{ $t('developer-tab.api-credentials.create-form.form.create') }}
        </button>
      </form>
    </div>

    <modal
      :isOpen="apiIdModalOpen"
      @dismiss="
        apiIdModalOpen = false
        $router.push({ name: 'api-credentials' })
      "
      v-if="createdCredential"
    >
      <div class="head">
        <h2 class="mt-0 mb-2">
          {{
            $t('developer-tab.api-credentials.create-form.success-modal.title')
          }}
        </h2>
        <p class="mt-0 mb-2">
          {{
            $t(
              'developer-tab.api-credentials.create-form.success-modal.description'
            )
          }}
        </p>
      </div>
      <div class="d-flex flex-column gap-2 w-100 body">
        <div class="">
          <p class="m-0 text-center">
            {{
              $t(
                'developer-tab.api-credentials.create-form.success-modal.api-id'
              )
            }}
          </p>
          <div
            class="d-flex justify-content-center align-items-center gap-2 p-2 border rounded"
            role="button"
            @click="copyText(createdCredential.apiId)"
          >
            <span class="api-id">
              {{ createdCredential.apiId }}
            </span>
            <i class="fa fa-clipboard" aria-hidden="true"></i>
          </div>
        </div>
        <div class="">
          <p class="m-0 text-center">
            {{
              $t(
                'developer-tab.api-credentials.create-form.success-modal.api-secret'
              )
            }}
          </p>
          <div
            class="d-flex justify-content-center align-items-center gap-2 p-2 border rounded"
            role="button"
            @click="copyText(createdCredential.apiSecret)"
          >
            <span class="api-secret">
              {{ createdCredential.apiSecret }}
            </span>
            <i class="fa fa-clipboard" aria-hidden="true"></i>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <b-button variant="primary" @click="downloadCredentials">
            {{
              $t(
                'developer-tab.api-credentials.create-form.success-modal.download-credentials'
              )
            }}</b-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { array, object, string } from 'yup'
import ApiGatewayCredentialsService from '../../../../services/apiGatewayCredentials.service'
import { yupToKV } from '../../../../utils/yup'
import Field from '../../../../elements/Field.vue'
import Modal from '../../../../elements/Modal'

export default {
  components: {
    Field,
    Modal
  },
  data() {
    return {
      form: {
        title: '',
        services: []
      },
      errors: {},
      createdCredential: null,
      apiIdModalOpen: false
    }
  },
  computed: {
    createSchema() {
      return object().shape({
        title: string()
          .trim()
          .min(
            2,
            this.$t(
              'developer-tab.api-credentials.create-form.form.title.minLength',
              { min: 2 }
            )
          )
          .required(
            this.$t(
              'developer-tab.api-credentials.create-form.form.title.required'
            )
          ),
        services: array()
          .of(
            string().required(
              this.$t(
                'developer-tab.api-credentials.create-form.form.service.required'
              )
            )
          )
          .min(
            1,
            this.$t(
              'developer-tab.api-credentials.create-form.form.services.minLength',
              { min: 1 }
            )
          )
      })
    },
    servicesOptions() {
      return [
        {
          id: 'pbx',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.pbx'
          )
        },
        {
          id: 'web-call',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.web-call'
          )
        },
        {
          id: 'order-confirmation',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.order-confirmation'
          )
        },
        {
          id: 'call-campaign',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.call-campaign'
          )
        },
        {
          id: 'billing',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.billing'
          )
        }
      ]
    }
  },
  methods: {
    async createCredential() {
      try {
        this.errors = {}
        const data = {
          ...this.form,
          services: this.form.services.map(service => service.id)
        }
        await this.createSchema
          .validate(data, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err)
            console.log(this.errors, data)
          })

        if (this.createSchema.isValidSync(data)) {
          const credential = await ApiGatewayCredentialsService.createCredential(
            data
          )
          this.createdCredential = credential
          this.apiIdModalOpen = true
          this.toast(
            this.$t('developer-tab.api-credentials.create-form.toasts.success')
          )
        }
      } catch (error) {
        console.log(error)
        this.toast(error.response.data.message, { type: 'error' })
      }
    },
    copyText(text) {
      try {
        navigator.clipboard.writeText(text)
        this.toast('Copied to clipboard')
      } catch (error) {
        console.error('Failed to copy: ', error)
        this.toast('Failed to copy', {
          type: 'error'
        })
      }
    },
    downloadCredentials() {
      const text = `API ID: ${this.createdCredential.apiId}\nAPI Secret: ${this.createdCredential.apiSecret}`
      const blob = new Blob([text], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `api-credentials-${
        this.createdCredential.apiId
      }-${new Date().getTime()}.txt`
      a.click()
      URL.revokeObjectURL(url)
    }
  }
}
</script>

<style scoped lang="scss">
.gap-2 {
  gap: 0.5rem;
}
.api-id {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.api-secret {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
